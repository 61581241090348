

  export default function parseAnswerAttached(response) {

    let { data, included = [] } = response;
    
    return data.map(applicant => {

      let biosketchId = applicant.relationships.field_biosketch?.data?.id;
      let currentResumeId = applicant.relationships.field_current_resume?.data?.id;
      let letterInterestId = applicant.relationships.field_letter_interest?.data?.id;
      let unofficialColleId = applicant.relationships.field_unofficial_colle?.data?.id;
      let unofficialTransId = applicant.relationships.field_unofficial_trans?.data?.id;
      let userId = applicant.relationships.uid?.data?.id;
      
      let biosketch = included.filter(biosketch => biosketch.type === 'file--file').find(biosketch => biosketch.id === biosketchId) || {};
      let currentResume = included.filter(item => item.type === 'file--file').find(item => item.id === currentResumeId) || {};
      let letterInterest = included.filter(item => item.type === 'file--file').find(item => item.id === letterInterestId) || {};
      let unofficialColle = included.filter(item => item.type === 'file--file').find(item => item.id === unofficialColleId) || {};   
      let unofficialTrans = included.filter(item => item.type === 'file--file').find(item => item.id === unofficialTransId) || {};     
      
      let lors = included.filter(lor => lor.type === 'node--lor_request' && lor.relationships.uid?.data?.id === userId) || [];
      
      return {
        ...applicant,
        attributes : {
          ...applicant.attributes,
          field_biosketch: {
            ...applicant.relationships?.field_biosketch,
            ...biosketch,
          },
          field_current_resume: {
            ...applicant.relationships?.field_current_resume,
            ...currentResume,
          },
          field_letter_interest: {
            ...applicant.relationships?.field_letter_interest,
            ...letterInterest,
          },
          field_unofficial_colle: {
            ...applicant.relationships?.field_unofficial_colle,
            ...unofficialColle,
          },
          field_unofficial_trans: {
            ...applicant.relationships?.field_unofficial_trans,
            ...unofficialTrans,
          },
          field_letters_of_recommendation: lors
        }
      }
    })
  }