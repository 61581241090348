import React from "react";
import Header from "gatsby-theme-carbon/src/components/Header";
import logo from "../../images/logo.svg";

const CustomHeader = (props) => (
  <Header {...props}>
    <img
      src={logo}
      alt="UW Medicine Neurological Surgery"
      style={{
        height: 38,
        width: "auto",
        marginRight: 15,
        padding: "6px 12px 0px",
      }}
    />
  </Header>
);

export default CustomHeader;
